import React from "react"
import { Helmet } from "react-helmet"

import Layout from "../components/Layout"
import * as styles from "../styles/about.module.css"
import { graphql, Link } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function About({ data }) {
  const certificate = getImage(data.certificate.childImageSharp.gatsbyImageData)
  const nellie = getImage(data.nellie.childImageSharp.gatsbyImageData)
  return (
    <Layout>
      <Helmet>
        <meta
          name="description"
          content="About web developer Patrick Oldman."
        />
        <title>Patrick Oldman | About</title>
      </Helmet>
      <section className={styles.about}>
        <h2>About Me</h2>
        <div style={{ marginTop: "10px" }}>
          <a
            href="https://github.com/Oldman10000"
            target="_blank"
            rel="noreferrer"
          >
            <strong>GitHub</strong> <FontAwesomeIcon icon={["fab", "github"]} />
          </a>{" "}
          |{" "}
          <a
            href="https://www.linkedin.com/in/patrick-oldman/"
            target="_blank"
            rel="noreferrer"
          >
            <strong>LinkedIn</strong>{" "}
            <FontAwesomeIcon icon={["fab", "linkedin"]} />
          </a>{" "}
          | <strong>patrickoldman1993@gmail.com</strong> |{" "}
          <strong>07518 218 659</strong> |{" "}
          <a href={"cv.pdf"} download="Patrick-Oldman-CV.pdf">
            <strong>Download My CV</strong>{" "}
            <FontAwesomeIcon icon="file-download" />
          </a>
        </div>
        <div className={styles.gridContainer}>
          <article>
            <p>
              I started my software development career at the start of 2021,
              enrolling in the Code Institute Full Stack Software Development
              Diploma.
              <br />
              <br />
              This course allowed me to work to my own schedule, learning web
              development from the very basics of HTML and CSS to mastering Full
              Stack frameworks like Django - picking up JavaScript, Python,
              Flask and SQL on the way.
              <br />
              <br />
              As part of the course, I developed 4 graded portfolio websites,
              each showcasing different development skills I have attained from
              Front to Back-End technologies. Check out my{" "}
              <Link to="/projects">
                <strong>Portfolio page</strong>
              </Link>{" "}
              to find out more.
              <br />
              <br />
              I was delighted to graduate from the course in August 2021 with
              the highest possible grade - Distinction!
              <br />
              <br />
              Since completing the course, I have worked at{" "}
              <Link to="https://www.tjs.co.uk" target="_blank">
                <strong>TJS Web Design</strong>
              </Link>{" "}
              in Lincolnshire. This meant learning PHP and the Symfony
              framework, which was somewhat of a challenge but very satisfying
              too.
            </p>
          </article>
          <figure>
            <GatsbyImage image={certificate} alt="Certificate" />
            <figcaption style={{ marginTop: "10px" }}>
              My CI Certificate
            </figcaption>
          </figure>
          <article>
            <p>
              I spend the majority of my free time with my dog Nellie. I live in
              the beautiful English countryside so there is no shortage of walks
              to take her on.
              <br />
              <br />
              My other hobbies include hiking, football and music. Every year, I
              go skiing in Austria - this inspired one of my websites!
              <br />
              <br />
            </p>
          </article>
          <figure>
            <GatsbyImage image={nellie} alt="Nellie" />
            <figcaption style={{ marginTop: "10px" }}>
              With Nellie in Wales
            </figcaption>
          </figure>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query MyQuery {
    certificate: file(relativePath: { eq: "certificate.png" }) {
      childImageSharp {
        gatsbyImageData(
          height: 500
          layout: CONSTRAINED
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    nellie: file(relativePath: { eq: "nellie.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          layout: CONSTRAINED
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`
